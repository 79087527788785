import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dates'
})
export class DatesPipe extends DatePipe implements PipeTransform {

  transform(value: any, ...args: any): any {
    return super.transform(value, 'yyyy-MM-ddTHH:mm:ss');;
  }

}
