import { BaseService } from './core/service/base.service';
import { Component, OnInit } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlatformLocation } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { locale, loadMessages } from 'devextreme/localization';
import config from 'devextreme/core/config';
import { AuthService } from './core/service/auth.service';
import { User } from './core/models/user';
import messages from '../assets/data/devextreme/locale.json';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { DisableRightClickService } from './core/service/disableRightClick.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUrl: string;
  jwtHelper = new JwtHelperService();
  previousUrl: string = null;
  presentUrl: string = null;
  constructor(
    public router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private baseService: BaseService,
    private translate: TranslateService,
    private rightClickDisable: DisableRightClickService
  ) {

    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });

  }
  ngOnInit() {
    // localStorage.clear();
    ///to detect previous url
    this.rightClickDisable.disableRightClick();
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.presentUrl;
      this.presentUrl = event.url;
    });

    loadMessages(messages);
    if (localStorage.getItem('dir')) {
      this.baseService.dir = localStorage.getItem('dir');
      this.baseService.lang = localStorage.getItem('lang');
      this.baseService.isRTL = localStorage.getItem('dir') === 'rtl' ? true : false;
    }
    else {
      this.baseService.dir = 'rtl';
      this.baseService.lang = 'ar';
      this.baseService.isRTL = true;
      localStorage.setItem('dir', this.baseService.dir);
      localStorage.setItem('lang', this.baseService.lang);
    }
    config({ rtlEnabled: this.baseService.isRTL, forceIsoDateParsing: true });
    locale(this.baseService.lang);
    this.translate.use(this.baseService.lang);
    document.getElementsByTagName('html')[0].setAttribute('dir', this.baseService.dir);
    document.getElementsByTagName('body')[0].classList.remove(this.baseService.dir === 'rtl' ? 'ltr' : 'rtl');
    document.getElementsByTagName('body')[0].classList.add(this.baseService.dir);

  }
}
