
import { environment } from 'src/environments/environment';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiObjectData } from '../models/api-object-data';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  @BlockUI() blockUI: NgBlockUI;
  isRTL: boolean;
  dir: string;
  lang: string;
  packageEnded: boolean;
  appUrl = environment.apiUrl + '/';
  apiUrl = this.appUrl + 'api/';
  linkUrl = environment.apiUrl;
  scanPopUp:boolean=false;
  requestFiles : any[]=[];
  requestType= "";
  scanfile = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
  }

  get(id: number, controller: string): Observable<ApiObjectData> {
    return this.http.get<ApiObjectData>(this.apiUrl + controller + '/' + id);
  }

  gets(controller: string): Observable<ApiObjectData> {
    return this.http.get<ApiObjectData>(this.apiUrl + controller + '/');
  }

  save(controller: string, model: any) {
    return this.http.post(this.apiUrl + controller + '/', model);
  }

  saveBatch(controller: string, model: any) {
    return this.http.post(this.apiUrl + controller + '/', model);
  }

  remove(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller + '/remove/' + id);
  }

  Remove(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller + '/Remove/' + id);
  }

  delete(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller + '/delete/' + id);
  }

  removes(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller + id)
  }

  post(controller: string, operation: string, model: any) {
    return this.http.post(this.apiUrl + controller + '/' + operation, model);
  }

  postFile(controller: string, operation: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post(this.apiUrl + controller + '/' + operation, formData);
  }

  uplaod(file: File) {
    const formData: FormData = new FormData();
    formData.append('fileKey', file, file.name);
    return this.http.post(this.apiUrl + 'Upload', formData);
  }

  find(searchText: string, controller: string): Observable<ApiObjectData> {
    return this.http.get<ApiObjectData>(
      this.apiUrl + controller + '/find/' + searchText
    );
  }

  observ(observ: any): Observable<ApiObjectData> {
    return observ.pipe(
      catchError(error => {
        this.alertService.error(this.translate.instant('toastrMsg.unexpectedError'));
        this.router.navigate(['']);
        return of(null);
      })
    );
  }

  observnav(observ: any, commands: any[]): Observable<ApiObjectData> {
    return observ.pipe(
      catchError(error => {
        this.alertService.error(this.translate.instant('toastrMsg.unexpectedError'));
        this.router.navigate(commands);
        return of(null);
      })
    );
  }

  resolveGet(
    service: any,
    route: ActivatedRouteSnapshot,
    url: string
  ): Observable<ApiObjectData> {
    if (route.params.type === 'edit' || route.params.type === 'view') {
      return this.observnav(service.get(route.params.id), [
        url,
        route.params.empId
      ]);
    } else {
      return of(null);
    }
  }

  resolveGets(service: any): Observable<ApiObjectData> {
    return this.observ(service.gets());
  }

  resolveGetsNotTempProcesses(service: any): Observable<ApiObjectData> {
    return this.observ(service.getsNotTempProcesses());
  }

  resolveGetOther(service: any): Observable<ApiObjectData> {
    return this.observ(service);
  }

  resolveOperation(operation: any): Observable<ApiObjectData> {
    return this.observ(operation);
  }

  blockStart() {
    if (this.isRTL) {
      this.blockUI.start('تحميل البيانات');
    } else {
      this.blockUI.start('Loading Data');
    }
  }

  blockStartMsg(msg: string) {
    this.blockUI.start(msg);
  }

  blockStop() {
    this.blockUI.stop();
  }

  convetDateToString(value: NgbDateStruct): string {
    let result = '';
    if (value && value.day != null) {
      result += value.year.toString();
      result += '/';
      if (value.month.toString().length === 1) {
        result += '0' + value.month.toString();
      } else {
        result += value.month.toString();
      }
      result += '/';
      if (value.day.toString().length === 1) {
        result += '0' + value.day.toString();
      } else {
        result += value.day.toString();
      }
      return result;
    }
    return '';
  }

  convetStringToDate(value: string): NgbDateStruct {
    if (value) {
      let dateParts = value
        .substring(0, 10)
        .trim()
        .split('-');
      if (dateParts.length === 3) {
        if (dateParts[0].length === 4) {
          return {
            year: Number(dateParts[0]),
            month: Number(dateParts[1]),
            day: Number(dateParts[2])
          };
        } else if (dateParts[2].length === 4) {
          return {
            day: Number(dateParts[0]),
            month: Number(dateParts[1]),
            year: Number(dateParts[2])
          };
        }
      }
      dateParts = value
        .substring(0, 10)
        .trim()
        .split('/');
      if (dateParts.length === 3) {
        if (dateParts[0].length === 4) {
          return {
            year: Number(dateParts[0]),
            month: Number(dateParts[1]),
            day: Number(dateParts[2])
          };
        } else if (dateParts[2].length === 4) {
          return {
            day: Number(dateParts[0]),
            month: Number(dateParts[1]),
            year: Number(dateParts[2])
          };
        }
      }
    }
    return null;
  }

  // openModal()
  // {
  //   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return  `with: ${reason}`;
  //   }
  // }
}

export interface RequestFile{
  reqType:any,
  reqTypeUrl:any,
}
