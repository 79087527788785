import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  ViewEncapsulation
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { TranslateService } from '@ngx-translate/core';
// import * as $ from 'jquery';
import { ApiObjectData } from 'src/app/core/models/api-object-data';
import { BaseComponent } from 'src/app/components/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/core/models/user';
import { BehaviorSubject, Subscription, timer } from 'rxjs';


import * as signalR from '@microsoft/signalr';
import { NotificationResult, NotificationCountResult } from '../../core/models/signalr-test/notification-net';
import { environment } from '../../../environments/environment';

import { Request } from '../../core/models/request';
import { RequestService } from '../../core/service/request.service';
// import { DataSharingService } from '../../core/service/data-sharing.service';
import { NotificationService } from '../../core/service/notifications.service';
import { Notification } from '../../core/models/notification';
import { BaseService } from '../../core/service/base.service';


import Notiflix from 'notiflix';
import { HubService } from './../../core/service/hub.service';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent extends BaseComponent implements AfterViewInit, OnInit {
  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  numberOfNotifications = 0;
  logoUrl: any;
  inspectionDetails: any;
  base64Image: any;
  currentUserSubject: BehaviorSubject<User>;
  errorMessage = '';
  showPack = false;
  days: number;
  selectedBgColor = 'white';
  maxHeight: string;
  maxWidth: string;
  showpanel = false;
  isOpenSidebar: boolean;
  isDarkSidebar = false;
  isDarTheme = false;
  toogled: boolean;


  notiRequests: any = [];
  newNotifications: any = [];
  notificationCount: any;


  notiProcessRequests: any = [];
  numberOfProcessNotifications = 0;

  // messages: Array<NotificationResult>;
  // notification: NotificationCountResult;

  isRequestApproved: boolean;
  token: any;
  user:any;
  subscription !: Subscription;

  public sidebarItems: any[];
  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public languageService: LanguageService,
    private translate: TranslateService,
    private rightSidebarService: RightSidebarService,
    private requestService: RequestService,
    private baseService: BaseService,
    private notificationService: NotificationService,
    public hubService:HubService

  ) {
    super();
    translate.setDefaultLang('ar');
    this.getNotification();
    this.getProcessNotification();

    this.intervalId = setInterval(() => {
      this.getNotification();
      this.getProcessNotification();
    }, 5000);

  }


  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' }
  ];
  isExpanded = false;

  notifications: Notification[];
  intervalId: any;
  



  getNotification() {
    this.requestService.getRequestsForApproval().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ApiObjectData) => {
        this.notiRequests = data.returnData;
        this.numberOfNotifications = this.notiRequests.length;
      }
    )
  }

  getProcessNotification() {
    this.requestService.getRequestsForApproveUnitLevel().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: ApiObjectData) => {
        this.notiProcessRequests = data.returnData;
        this.numberOfProcessNotifications = this.notiProcessRequests.length;
      }
    )
  }

  ngOnInit() {

    this.config = this.configService.configData;

    // this.subscription = timer(0, 5000).pipe(switchMap(async () => this.loadData())).subscribe(result => 
    //   console.log(result)
    // );

    this.loadData();

    this.user = JSON.parse(localStorage.getItem('user'))
      
    this.hubService.startConnection(this.user.id);
    this.hubService.updateRequestNotificationDataListener();

    setTimeout(()=>{
      this.hubService.getMsgFromServer();
    },2000);


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
}



  approveRequest(id) {
    this.router.navigate(['/page/approval-request/edit', id])
    $('.dropdown').removeClass('show');
    $('.dropdown-menu').removeClass('show');
    $('.collapse').removeClass('show').attr('ng-reflect-collapsed', 'true');
    $('.dropdown-toggle').attr('aria-expanded', 'false');
  }

  loadData() {

  }

  ViewRequest(data) {
    this.requestService.getRequestsForApproval().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: ApiObjectData) => {
        this.notiRequests = res.returnData as Request;
      }
    )


    $('.dropdown-notifications').removeClass('show');
    $('.dropdown-menu').removeClass('show');
    $('.collapse').removeClass('show').attr('ng-reflect-collapsed', 'true');
    $('.dropdown-toggle').attr('aria-expanded', 'false');

    this.router.navigate([data.dataUrl]);
  }
  closeDropDown(url) {

    $('.dropdown').removeClass('show');
    $('.dropdown-menu').removeClass('show');
    $('.collapse').removeClass('show').attr('ng-reflect-collapsed', 'true');
    $('.dropdown-toggle').attr('aria-expanded', 'false');

    this.router.navigate([url]);
  }
  changeLang() {
    const lang = this.languageService.getLanguage();
    if (lang === 'English') {
      this.languageService.setLayout('ltr', 'rtl');
      this.languageService.setLanguage('en');
    } else if (lang === 'العربية') {
      this.languageService.setLayout('rtl', 'ltr');
      this.languageService.setLanguage('ar');
    }
    parent.document.location.reload();
  }



  ngAfterViewInit() {
    if (localStorage.getItem('choose_skin')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_skin')
      );
      this.selectedBgColor = localStorage.getItem('choose_skin_active');
    } else {
      this.renderer.addClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
      this.selectedBgColor = this.config.layout.theme_color;
    }

    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'dark') {
        this.isDarTheme = true;
      } else if (localStorage.getItem('theme') === 'light') {
        this.isDarTheme = false;
      } else {
        this.isDarTheme = this.config.layout.variant === 'dark' ? true : false;
      }
    } else {
      this.isDarTheme = this.config.layout.variant === 'dark' ? true : false;
    }
  }

  selectTheme(e) {
    this.selectedBgColor = e;
    const prevTheme = this.elementRef.nativeElement
      .querySelector('.settingSidebar .choose-theme li.active')
      .getAttribute('data-theme');
    this.renderer.removeClass(this.document.body, 'theme-' + prevTheme);
    this.renderer.addClass(this.document.body, 'theme-' + this.selectedBgColor);
    localStorage.setItem('choose_skin', 'theme-' + this.selectedBgColor);
    localStorage.setItem('choose_skin_active', this.selectedBgColor);
  }

  lightThemeBtnClick() {
    this.renderer.removeClass(this.document.body, 'dark');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
    this.renderer.removeClass(this.document.body, 'menu_dark');
    this.renderer.removeClass(this.document.body, 'logo-black');
    if (localStorage.getItem('choose_skin')) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem('choose_skin')
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
    }

    this.renderer.addClass(this.document.body, 'light');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    this.renderer.addClass(this.document.body, 'menu_light');
    this.renderer.addClass(this.document.body, 'logo-white');
    this.renderer.addClass(this.document.body, 'theme-white');
    const theme = 'light';
    const menuOption = 'menu_light';
    this.selectedBgColor = 'white';
    this.isDarkSidebar = false;
    localStorage.setItem('choose_logoheader', 'logo-white');
    localStorage.setItem('choose_skin', 'theme-white');
    localStorage.setItem('theme', theme);
    localStorage.setItem('menuOption', menuOption);
  }

  darkThemeBtnClick() {
    this.renderer.removeClass(this.document.body, 'light');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
    this.renderer.removeClass(this.document.body, 'menu_light');
    this.renderer.removeClass(this.document.body, 'logo-white');
    if (localStorage.getItem('choose_skin')) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem('choose_skin')
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        'theme-' + this.config.layout.theme_color
      );
    }
    this.renderer.addClass(this.document.body, 'dark');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    this.renderer.addClass(this.document.body, 'menu_dark');
    this.renderer.addClass(this.document.body, 'logo-black');
    this.renderer.addClass(this.document.body, 'theme-black');
    const theme = 'dark';
    const menuOption = 'menu_dark';
    this.selectedBgColor = 'black';
    this.isDarkSidebar = true;
    localStorage.setItem('choose_logoheader', 'logo-black');
    localStorage.setItem('choose_skin', 'theme-black');
    localStorage.setItem('theme', theme);
    localStorage.setItem('menuOption', menuOption);
  }

  changeTheme() {
    if (!this.toogled) {
      this.darkThemeBtnClick()
    } else {
      this.lightThemeBtnClick()
    }
    this.toogled = !this.toogled;
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    const bigLogo = this.document.getElementById('big-Logo');
    const smallLogo = this.document.getElementById('small-Logo');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');


      this.renderer.removeClass(bigLogo, 'hide-logo');
      this.renderer.removeClass(smallLogo, 'show-logo');
      this.renderer.addClass(bigLogo, 'show-logo');
      this.renderer.addClass(smallLogo, 'hide-logo');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      this.renderer.removeClass(bigLogo, 'show-logo');
      this.renderer.removeClass(smallLogo, 'hide-logo');
      this.renderer.addClass(bigLogo, 'hide-logo');
      this.renderer.addClass(smallLogo, 'show-logo');
    }
  }
  public toggleRightSidebar(): void {
  }
  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

}
function loadData() {
  throw new Error('Function not implemented.');
}

