<nav #navbar class="navbar">
  <div class="container-fluid">
    <div class="navbar-header d-flex align-items-center flex-row-reverse" style="padding: 0; border-right:unset">
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>

      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a class="navbar-brand show-logo" id="big-Logo" routerLink="page/home" style="height: 60px;margin: unset;">
        <img src="assets/images/msc-logo-full.png" alt="" style="float: none; margin-top: 5px;" />
      </a>
      <a class="navbar-brand hide-logo" id="small-Logo" routerLink="page/home" style="height: 60px;margin: unset;padding: 10px;">
        <img src="assets/images/msc-logo-mini.png" alt="" />
        <span class="logo-name my-auto">{{"SystemTitle" | translate}}</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" id="collapse " [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <i class="nav-hdr-btn ti-align-left"></i>
          </button>

        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- <li class="light-dark">
          <label class="switch">
            <input type="checkbox" (click)="changeTheme()">
            <span class="slider"></span>
          </label>
        </li> -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <i class="nav-hdr-btn ti-fullscreen"></i>
          </button>
        </li>

        <li class="nav-item dropdown-notifications" ngbDropdown id="dropdown-notifications">
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <i class="nav-hdr-btn ti-announcement" title="إشعارات المعالجة"></i>
            <span class="notification noti-mobile">{{numberOfProcessNotifications}}</span>
          </button>

          <div ngbDropdownMenu id="notificationdropdown" class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li *ngFor="let notification of notiProcessRequests , let i = index">
                  <a *ngIf="i<6" class="notification-item">
                    <div class="notification-content d-flex justify-content-between align-items-start" (click)="approveRequest(notification.id)">
                      <div class="notification-detalis">
                        <p class="notification-name m-0"><span style="color: #333;">طلب :</span> {{notification.requestStatusNameAr}}</p>
                        <p class="notification-describtion">رقم الطلب : {{notification.requestNo}}</p>
                        <p class="notification-describtion">اسم الطالب : {{notification.personNameAr}}</p>
                      </div>
                      <span class="notification-time d-flex align-items-center">
                        <i class="far fa-clock" style="font-size: 14px;height: 16px;margin-left: 5px;"></i>
                         {{notification.requestDate | date:'shortDate'}} 
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1" (click)="closeDropDown('/page/request-process')"
            style="color: var(--subColor);text-decoration: none; cursor: pointer;">
            {{'readAllNotifications' | translate}}
          </a>
          </div>
        </li>

        <li class="nav-item dropdown-notifications" ngbDropdown id="dropdown-notifications">
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <i class="nav-hdr-btn ti-bell" title="إشعارات الاعتماد"></i>
            <span class="notification noti-mobile">{{numberOfNotifications}}</span>
          </button>
          <div ngbDropdownMenu id="notificationdropdown" class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li *ngFor="let notification of notiRequests , let i = index">
                  <a *ngIf="i<6" class="notification-item">
                    <div class="notification-content d-flex justify-content-between align-items-start" (click)="approveRequest(notification.id)">
                      <div class="notification-detalis">
                        <p class="notification-name m-0"><span style="color: #333;">طلب :</span> {{notification.requestStatusNameAr}}</p>
                        <p class="notification-describtion">رقم الطلب : {{notification.requestNo}}</p>
                        <p class="notification-describtion">اسم الطالب : {{notification.personNameAr}}</p>
                      </div>
                      <span class="notification-time d-flex align-items-center">
                        <i class="far fa-clock" style="font-size: 14px;height: 16px;margin-left: 5px;"></i>
                         {{notification.requestDate | date:'shortDate'}} 
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1" (click)="closeDropDown('/page/request-approvals')"
              style="color: var(--subColor);text-decoration: none; cursor: pointer;">
              {{'readAllNotifications' | translate}}
            </a>
          </div>
        </li>
        <!-- #END# Notifications-->

        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User">
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown ">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li (click)="closeDropDown('/page/Profile')">
                      <a href="#" onClick="return false;" style="font-size: larger">
                        <!-- <mat-icon style="margin: 0 5px;" aria-hidden="false" class="mr-2">person</mat-icon> -->
                        <i class="fas fa-user mr-2" style="margin: 0 5px;" aria-hidden="false"></i>
                        {{'Profile' | translate}}
                      </a>
                    </li>
                    <li (click)="closeDropDown('/page/changePassword')">
                      <a href="#" onClick="return false;" style="font-size: larger">
                        <!-- <mat-icon style="margin: 0 5px;" aria-hidden="false" class="mr-2 ml-2">password</mat-icon> -->
                        <i class="fas fa-unlock mr-2 ml-2" style="margin: 0 5px;" aria-hidden="false"></i>
                        {{'ChangePassword'| translate}}
                      </a>
                    </li>
                    <li *ngIf="this.showPack===true" (click)="closeDropDown('/page/myPackages')">
                      <a href="#" onClick="return false;" style="font-size: larger">
                        <mat-icon style="margin: 0 5px;" aria-hidden="false" class="mr-2 ml-2">card_membership</mat-icon>
                        {{"MyPackages" | translate}}
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()" style="font-size: larger">
                        <!-- <mat-icon style="margin: 0 5px;" aria-hidden="false" class="mr-2">power_settings_new</mat-icon> -->
                        <i class="fas fa-sign-out-alt mr-2" style="margin: 0 5px;" aria-hidden="false"></i>
                        {{'logout' | translate}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>

      <ul class="nav navbar-nav navbar-center" *ngIf="this.days<=15">
        <li>
          <a>
            <img src="../../../assets/images/icons/warning-blink.gif" alt="" width="20px" style="margin: 0 5px;">
            {{'remain' | translate}} {{this.days}} {{'days' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>>
