import { BaseService } from '../service/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Notification } from '../models/notification';
import { ApiObjectData } from '../models/api-object-data';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {
    controller = 'Notification';

    constructor(private base: BaseService) {}

    notificationCount(): Observable<ApiObjectData> {
        return this.base.gets(this.controller + '/NotificationCount');
    }

    notificationMessage():  Observable<ApiObjectData> {
        return this.base.gets(this.controller + '/NotificationMessage');
    }
}