import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { BaseService } from './base.service';
import { Request } from '../models/request';

@Injectable({
    providedIn: 'root'
})

export class RequestService {

    controller = 'Request';
    constructor(private base: BaseService) { }

    get(id: any): Observable<ApiObjectData> {
        return this.base.get(id, this.controller);
    }

    gets(): Observable<ApiObjectData> {
        return this.base.gets(this.controller);
    }

    checkPersonIsExsist(identiyNo: any,screenName:string) {
        return this.base.gets(this.controller+'/CheckPersonIsExsist/'+identiyNo+'/'+screenName)
    }

    CheckPersonIsExsistWithParentUnit(identiyNo: any,screenName:string) {
        return this.base.gets(this.controller+'/CheckPersonIsExsistWithParentUnit/'+identiyNo+'/'+screenName)
    }

    EnquiryRequest(identiyNo: any) {
        return this.base.gets(this.controller+'/EnquiryRequest/'+identiyNo)
    }

    getAllRequestsByType(typeId: any) {
        return this.base.gets(this.controller+'/GetAllRequestsByType/'+typeId)
    }

    getRequestsForEdit(){
        return this.base.gets(this.controller+'/GetRequestsForEdit');
    }

    getRequestsForApproval(){
        return this.base.gets(this.controller+'/GetRequestsForApprove');
    }

    getRequestsForApproveUnitLevel() {
        return this.base.gets(this.controller+'/GetRequestsForApproveUnitLevel')
    }

    aproveRequest(obj: Request){
        return this.base.save(this.controller+'/RequestApprove', obj);
    }

    rejectRequest(obj: Request) {
        return this.base.save(this.controller+'/RejectRequest', obj);
    }

    cardsWatingPrint(){
        return this.base.gets(this.controller+'/GetCardsForPrint');
    }

    searchInRequest(obj: Request) {
        return this.base.save(this.controller+'/SearchInRequest', obj);
    }

    updateRequest(id: any) {
        return this.base.gets(this.controller+'/UpdateRequest/'+id)
    }

    save(obj: Request) {
        return this.base.save(this.controller, obj);
    }

    remove(id: any) {
        return this.base.Remove(this.controller, id);
    }
}