// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://matrixzone.ddns.net:30',
  // url: 'matrixzone.ddns.net:30',
  // apiUrl: 'http://192.168.1.100:30',
  // url: '192.168.1.100:30',
  // apiUrl:'https://api.card.aldawal.com',
  // url: 'api.card.aldawal.com',
  apiUrl: 'http://localhost:2022',
  url: 'localhost:2022',

  // apiUrl: 'http://rms-aldawal.ddns.net:30',
  // url: 'rms-aldawal.ddns.net:30',
  SSO: false,
  Dynamsoft: {
    resourcesPath: 'assets/dwt-resources',
    dwtProductKey:
    "t01529gIAAFgQdFXSNJKSuLsnmRs6ryzC1z+o1FeE2gAmJFo/Q/hZWOSLz+owY6BDZDunMqOW3YdL7p9bmWg9oGyEvLc8BQtIzY0iMFi6qK9KbSrO6AauHXAA+CJ70F/5Aje+b6MKDmgGmIDejR/gOOSnVxqE9EAzwAQch3Qg6GRt31QehKwjpIRAM8AEjJAOGJdaLjY/AYRgmhc=",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
